import { API } from "@/apps/core/api";
import FormModel from "@/apps/core/models/formModel";
import ListAPI from "@/apps/core/api/list";
import PlainModel from "@/apps/core/models/plainModel";
import ViewListModel from "@/apps/core/models/viewListModel";

class Diagnosis extends PlainModel {
  kode: string | null = null;
  nama: string | null = null;
}

class DiagnosisAPI extends API {
  static modelPath = "/penyakit/diagnosis/";

  constructor() {
    super(DiagnosisAPI.modelPath);
  }
}

class ListDiagnosisAPI extends ListAPI {
  constructor() {
    super(DiagnosisAPI.modelPath);
  }
}

class ListDiagnosisVM extends ViewListModel {
  constructor() {
    super(new ListDiagnosisAPI());
  }

  getInstance(json: Record<string, any>) {
    const diagnosis = new Diagnosis();
    diagnosis.loadData(json);
    return diagnosis;
  }
}

class DiagnosisFM extends FormModel {
  constructor() {
    super(new DiagnosisAPI(), new Diagnosis(), ["nama"], []);
  }
}

export {
  Diagnosis,
  DiagnosisAPI,
  DiagnosisFM,
  ListDiagnosisAPI,
  ListDiagnosisVM,
};
