















































import { defineComponent } from "@vue/composition-api";
import FormModel from "@/apps/core/models/formModel";
import { KeterampilanFM } from "../models/tindakan";
import { PemeriksaanFM } from "../models/pemeriksaan";
import { TatalaksanaFM } from "../models/tatalaksana";
import useFormModel from "@/apps/core/modules/useFormModel";

const FormGetterMap: Record<string, Function> = {
  pemeriksaan: () => new PemeriksaanFM(),
  tatalaksana: () => new TatalaksanaFM(),
  keterampilan: () => new KeterampilanFM(),
};

export default defineComponent({
  name: "KomponenForm",
  props: {
    headerText: { type: String, required: true },
    formModel: Object,
  },
  components: {
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup(props) {
    let form: FormModel;
    if (props.formModel) {
      form = props.formModel as FormModel;
    } else {
      const komponenStr = props.headerText.toLowerCase();
      form = FormGetterMap[komponenStr]() as FormModel;
    }

    const composition = useFormModel(form);
    const komponen = composition.instance;

    return {
      // Data
      komponen,

      // Composition
      ...composition,
    };
  },
});
