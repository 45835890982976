import { API } from "@/apps/core/api";
import ListAPI from "@/apps/core/api/list";
import PlainModel from "@/apps/core/models/plainModel";

class Komponen extends PlainModel {
  kode: string | null = null; // TODO: Cek, tidak ada di DB??!!!
  nama: string | null = null;
}

class KomponenAPI extends API {
  constructor(modelPath: string) {
    super(modelPath);
  }
}

class ListKomponenAPI extends ListAPI {
  constructor(modelPath: string) {
    super(modelPath);
  }
}

// class ListKomponenVM extends ViewListModel {
//   constructor() {
//     super(new ListKomponenAPI());
//   }

//   getInstance(json: Record<string, any>) {
//     const pemeriksaan = new Pemeriksaan();
//     pemeriksaan.loadData(json);
//     return pemeriksaan;
//   }
// }

// class KomponenFM extends FormModel {
//   constructor() {
//     super(new PemeriksaanAPI(), new Pemeriksaan(), ["nama"], []);
//   }
// }

export { Komponen, KomponenAPI, ListKomponenAPI };
