











































import { defineComponent } from "@vue/composition-api";
import { DiagnosisFM } from "../models/diagnosis";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "DiagnosisForm",
  components: {
    KomponenForm: () => import("@/apps/penyakit/views/KomponenForm.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  props: {
    headerText: { type: String, required: true },
  },
  setup() {
    const form = new DiagnosisFM();
    const composition = useFormModel(form);
    const diagnosis = composition.instance;

    return {
      // Data
      form,
      diagnosis,

      // Composition
      ...composition,
    };
  },
});
